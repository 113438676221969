import { createContext, Reducer, useContext, useMemo } from "react";
import { useReducerAsync, AsyncActionHandlers } from "use-reducer-async";
import {
  Category,
  ExternalOrderInput,
  ExternalOrderItem,
  InternalOrderInput,
  Order,
  Payment,
  Product,
  Shop,
} from "../DAL/Model";
import { CategoryRepository } from "../DAL/repositories/local/CategoryRepository";
import { OptionRepository } from "../DAL/repositories/local/OptionRepository";
import { PriceRepository } from "../DAL/repositories/local/PriceRepository";
import { ProductRepository } from "../DAL/repositories/local/ProductRepository";
import { ShopRepository } from "../DAL/repositories/local/ShopRepository";
import { ResourceOperationEvent } from "../DAL/ResourceEvents";
import {
  batchUploadImage2S3,
  UploadImage,
  uploadImage2S3,
} from "../utils/storage";
import { customAlphabet } from "nanoid";
import liff from "@line/liff";
import { OrderRepository } from "../DAL/repositories/local/OrderRepository";

const longAlphabet =
  "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
const shortAlphabet = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const nanoid = customAlphabet(longAlphabet, 16);
//100 IDs per hour:~17 days needed, in order to have a 1% probability of at least one collision.
const receiptId = customAlphabet(shortAlphabet, 7);

export type OrderTransaction = {
  status: OrderTransactionStatus;
  message: string | null | undefined;
};

export enum OrderTransactionStatus {
  entry,
  processing,
  succeed,
  error,
}

export type STORE = {
  shop: Shop | null;
  customer?: string | null;
  menu: Product[] | null;
  category: Category[] | null;
  lineId: string | null;
  payment: Payment;
  illustPlatePreOrder: Order | null;
  orderTransaction: OrderTransaction | null;
};

const initialStore: STORE = {
  shop: null,
  customer: null,
  menu: null,
  category: null,
  lineId: null,
  payment: {
    id: `${nanoid()}`,
    orders: null,
    total: 0,
  },
  illustPlatePreOrder: null,
  orderTransaction: null,
};

export type ASYNCACTION =
  | {
      type: "APP_DATA_FETCHING";
      payload: null;
    }
  | {
      type: "START_ORDER";
      payload: {
        internalInput: InternalOrderInput;
        payment: Payment;
      };
    };

export type ACTIONTYPE =
  | { type: ResourceOperationEvent.FETCH_MENU; payload: Product[] }
  | { type: ResourceOperationEvent.FETCH_CATEGORY; payload: Category[] }
  | { type: ResourceOperationEvent.GET_SHOP; payload: Shop }
  | { type: ResourceOperationEvent.GET_CUSTOMER; payload: null }
  | { type: ResourceOperationEvent.CREATE_ORDER; payload: null }
  | { type: ResourceOperationEvent.FETCH_LIFF; payload: string }
  | { type: ResourceOperationEvent.ADD_PAYMENT; payload: Order }
  | { type: ResourceOperationEvent.ADD_ILLUST_PRE_ORDER; payload: Order }
  | { type: ResourceOperationEvent.DELETE_ORDER; payload: string }
  | { type: ResourceOperationEvent.RESET_ALL; payload: null }
  | {
      type: ResourceOperationEvent.SET_ORDER_TRANSACTION;
      payload: OrderTransaction;
    }
  | { type: ResourceOperationEvent.ADD_ILLUST_ORDER; payload: UploadImage };

const ApplicationStoreContext = createContext(
  {} as {
    store: STORE;
    dispatch: React.Dispatch<ACTIONTYPE | ASYNCACTION>;
  }
);

const reducer = (store: STORE, action: ACTIONTYPE) => {
  switch (action.type) {
    case ResourceOperationEvent.GET_SHOP:
      return { ...store, shop: action.payload };
    case ResourceOperationEvent.GET_CUSTOMER:
      return { ...store, customer: action.payload };
    case ResourceOperationEvent.FETCH_MENU:
      return {
        ...store,
        menu: action.payload,
      };
    case ResourceOperationEvent.FETCH_CATEGORY:
      return {
        ...store,
        category: action.payload,
      };
    case ResourceOperationEvent.FETCH_LIFF:
      return { ...store, lineId: action.payload };
    case ResourceOperationEvent.ADD_PAYMENT:
      const productTotal =
        action.payload.price?.amount! * action.payload.unit ?? 0;
      const optionTotal =
        action.payload.options?.reduce(
          (acc, cur) => acc + cur?.price.amount! * cur?.unit!,
          0
        ) ?? 0;
      return {
        ...store,
        payment: {
          ...store.payment,
          orders: [...(store.payment.orders ?? []), action.payload],
          total: store.payment.total + productTotal + optionTotal,
        },
      };
    case ResourceOperationEvent.ADD_ILLUST_PRE_ORDER:
      return {
        ...store,
        illustPlatePreOrder: action.payload,
      };
    case ResourceOperationEvent.ADD_ILLUST_ORDER:
      const illustTotal =
        store.illustPlatePreOrder!.price?.amount! *
          store.illustPlatePreOrder!.unit ?? 0;
      const illustOptionTotal =
        store.illustPlatePreOrder!.options?.reduce(
          (acc, cur) => acc + cur?.price.amount! * cur?.unit!,
          0
        ) ?? 0;
      return {
        ...store,
        payment: {
          ...store.payment,
          orders: [
            ...(store.payment.orders ?? []),
            { ...store.illustPlatePreOrder, image: action.payload } as Order,
          ],
          total: store.payment.total + illustTotal + illustOptionTotal,
        },
        illustPlatePreOrder: null,
      };
    case ResourceOperationEvent.SET_ORDER_TRANSACTION:
      return {
        ...store,
        orderTransaction: action.payload,
      };
    case ResourceOperationEvent.RESET_ALL:
      return {
        shop: null,
        customer: null,
        menu: null,
        category: null,
        lineId: null,
        payment: {
          id: `${nanoid()}`,
          orders: null,
          total: 0,
        },
        illustPlatePreOrder: null,
        orderTransaction: null,
      };
    case ResourceOperationEvent.DELETE_ORDER:
      const deleteOrder = store.payment.orders?.find(
        (order) => order.id === action.payload
      )!;
      const deleteOptionTotal = deleteOrder.options?.reduce(
        (acc, cur) => acc + cur.price.amount * cur.unit,
        0
      ) ?? 0
      return {
        ...store,
        payment: {
          ...store.payment,
          orders:
            store.payment.orders?.filter(
              (order) => order.id !== action.payload
            ) ?? null,
          total:
            store.payment.total -
            deleteOrder.price!.amount * deleteOrder?.unit - deleteOptionTotal
            
        },
      };
    default:
      return store;
  }
};

const asyncActionHandlers: AsyncActionHandlers<
  Reducer<STORE, ACTIONTYPE>,
  ASYNCACTION
> = {
  APP_DATA_FETCHING:
    ({ dispatch }) =>
    async (action) => {
      const shopClient = ShopRepository();
      const productClient = ProductRepository();
      const priceClient = PriceRepository();
      const optionClient = OptionRepository();
      const categoryClient = CategoryRepository();
      await Promise.all([
        shopClient.getShop(),
        productClient.getList(),
        priceClient.getList(),
        optionClient.getList(),
        categoryClient.getList(),
      ]).then((results) => {
        dispatch({
          type: ResourceOperationEvent.GET_SHOP,
          payload: results[0],
        });
        const product = results[1].map((prod) => {
          const price = results[2].filter(
            (price) => price.productId === prod.id
          );
          const options = results[3]
            .filter((opt) => prod.optionId?.includes(opt.id))
            .map((opt) => {
              return {
                ...opt,
                price: results[2].filter((price) => price.optionId === opt.id),
              };
            });
          return {
            ...prod,
            price: price,
            options: options,
          } as Product;
        });

        dispatch({
          type: ResourceOperationEvent.FETCH_MENU,
          payload: product,
        });
        dispatch({
          type: ResourceOperationEvent.FETCH_CATEGORY,
          payload: results[4],
        });
      });
    },
  START_ORDER:
    ({ dispatch }) =>
    async (action) => {
      dispatch({
        type: ResourceOperationEvent.SET_ORDER_TRANSACTION,
        payload: {
          status: OrderTransactionStatus.entry,
          message: "注文処理を開始しました。",
        },
      });
      const orderClient = OrderRepository(liff.getAccessToken());
      const orders = action.payload.payment.orders!.map((order) => {
        return order.image
          ? {
              ...order,
              image: {
                ...order.image,
                name: `${action.payload.payment.id}_${action.payload.internalInput.name}_${order.image?.name}`,
              },
            }
          : order;
      });
      const uploadImages = orders
        .filter((order) => order.image)
        .map((order) => order.image!);
      const orderItems: ExternalOrderItem[] = orders!.map((order) => {
        return {
          id: order.id,
          name: order.category === "ホールケーキ" ? `${order.productName} ${order.price!.name}`: order.price!.name,
          category: order.category,
          amount: order.unit,
          price: order.price?.amount!,
          image: order.image ? order.image?.name! : undefined,
          options:
            order.options?.map((option) => {
              return {
                name: option.price.name,
                amount: option.unit,
                price: option.price.amount,
              };
            }) ?? [],
        };
      });
      //console.log(orderItems)
      const createOrderInput: ExternalOrderInput = {
        restaurant_id: parseInt(process.env.REACT_APP_RESTAURANT_ID!),
        id: action.payload.payment.id,
        receipt_num: receiptId(),
        payment_intent_id: null,
        payment_way: action.payload.internalInput.paymentWay,
        status: "new",
        name: action.payload.internalInput.name,
        phone: action.payload.internalInput.tel,
        date: action.payload.internalInput.date,
        total: action.payload.payment.total,
        items: orderItems,
        memo: action.payload.internalInput.memo ?? "",
        channel_id: process.env.REACT_APP_LINE_CHANNEL_ID!,
        user_id: null,
        user_token: liff.getIDToken()!,
        operation_token: "",
        created_at: new Date().toLocaleString(),
      };
      //console.log(createOrderInput);
      //console.log(uploadImages);
      if (uploadImages.length > 0) {
        dispatch({
          type: ResourceOperationEvent.SET_ORDER_TRANSACTION,
          payload: {
            status: OrderTransactionStatus.processing,
            message: "イラストプレート画像のアップロード中",
          },
        });
        await batchUploadImage2S3(uploadImages);
      }
      dispatch({
        type: ResourceOperationEvent.SET_ORDER_TRANSACTION,
        payload: {
          status: OrderTransactionStatus.processing,
          message: "注文データの送信中。",
        },
      });
      await orderClient
        .createOrder(createOrderInput)
        .then((_) => {
          if (liff.isInClient()) {
            liff.sendMessages([
              {
                type: "text",
                text: "lto:" + action.payload.payment.id,
              },
            ]);
          }
          dispatch({
            type: ResourceOperationEvent.SET_ORDER_TRANSACTION,
            payload: {
              status: OrderTransactionStatus.succeed,
              message: "注文が完了しました。",
            },
          });
        })
        .catch((err) => {
          dispatch({
            type: ResourceOperationEvent.SET_ORDER_TRANSACTION,
            payload: {
              status: OrderTransactionStatus.error,
              message: "注文が失敗しました。",
            },
          });
        });
    },
};

type Props = {
  children?: React.ReactNode;
};

export const ApplicationStoreProvider: React.FC<Props> = (props) => {
  const [store, dispatch] = useReducerAsync<
    Reducer<STORE, ACTIONTYPE>,
    ASYNCACTION,
    ACTIONTYPE | ASYNCACTION
  >(reducer, initialStore, asyncActionHandlers);
  const value = useMemo(() => ({ store, dispatch }), [dispatch, store]);

  return <ApplicationStoreContext.Provider value={value} {...props} />;
};

export const useApplicationStore = () => {
  const context = useContext(ApplicationStoreContext);
  if (typeof context === "undefined") {
    throw new Error(
      "useApplicationStore must be within a ApplicationStoreProvider."
    );
  }
  return context;
};
