import { createTheme, PaletteMode } from "@mui/material";
import { grey, red, teal } from "@mui/material/colors";

declare module "@mui/material/styles" {
  interface Palette {
    border: {
      main: string;
    };
    required: {
      main: string;
    }
  }
  interface PaletteOptions {
    border?: {
      main?: string;
    };
    required?: {
      main: string;
    }
  }
  interface TypographyVariants {
    error: React.CSSProperties;
  }
  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    error?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    error: true;
  }
}

export const LiffReservationTheme = (mode: PaletteMode) =>
  createTheme({
    palette: {
      primary: {
        main: mode === "light" ? teal["400"] : "#6184d8",
        light: mode === "light" ? teal["100"] : "#8da6e2",
        dark: mode === "light" ? teal["800"] : "#6184d8",
        contrastText: "#fcf7f8",
      },
      secondary: {
        main: "#fcf7f8",
        light: "#fcf7f8",
        dark: "#f9f0f2",
        contrastText: teal["500"],
      },
      background: {
        default: mode === "light" ? "#fcf7f8" : "#1a1423",
        paper: mode === "light" ? "#fcf7f8" : "#1a1423",
      },
      text: {
        primary: mode === "light" ? "#1a1423" : "#fcf7f8",
        secondary: mode === "light" ? "#1a1423" : "#f9f0f2",
      },
      border: {
        main: grey[300],
      },
      required:{
        main: red[300]
      },
      mode: mode,
    },
    typography: {
      fontFamily: ['"Noto Sans JP"', '"Helvetica"', "Arial", "sans-serif"].join(
        ","
      ),
      h1: {
        fontSize: 24,
      },
      h2: {
        fontSize: 18,
      },
      h3: {
        fontSize: 16,
      },
      h4: {
        fontSize: 14,
      },
      h5: {
        fontSize: 12,
      },
      body1: {
        fontSize: 16,
      },
      body2: {
        fontSize: 14,
      },
      subtitle1: {
        fontSize: 14,
      },
      subtitle2: {
        fontSize: 12,
      },
      error:{
        fontSize: 14,
        color: mode === "light" ? "#df1b41" : "#fe87a1"
      }
    },

  });
