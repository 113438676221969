import {Price} from "../../Model"

export const PriceRepository = () =>{
  const getList = () =>{
    return new Promise<Price[]>(async(res, rej)=>{
      await fetch(`${process.env.REACT_APP_APP_ROOT}/data/price.json`,{
        method: "GET"
      }).then((result)=>
        res(result.json() as unknown as Price[])
      ).catch((err)=>rej(err))
    })
  }
  const getList2 = () =>{
    return new Promise<Price[]>(async(res, rej)=>{
      await fetch(`${process.env.REACT_APP_APP_ROOT}/data/price2.json`,{
        method: "GET"
      }).then((result)=>
        res(result.json() as unknown as Price[])
      ).catch((err)=>rej(err))
    })
  }
  return {getList, getList2}
}