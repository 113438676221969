import { ListItemButton, Stack, Typography } from "@mui/material";
import React from "react";
import { Price } from "../../DAL/Model";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { grey } from "@mui/material/colors";

type Props = {
  option: Price;
  selectedOptionId?: string | null;
  clickHandler: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    option: Price
  ) => void;
};

export const OptionRadioInput = React.memo<Props>(({option,selectedOptionId, clickHandler}) => {
  return (
    <ListItemButton
      sx={{ p: 0 }}
      onClick={(e)=>clickHandler(e, option)}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        p={1.5}
        width="100%"
      >
        <Stack direction="row">
          {selectedOptionId === option.id ? (
            <RadioButtonCheckedIcon
              fontSize="small"
              color="primary"
            />
          ) : (
            <RadioButtonUncheckedIcon
              fontSize="small"
              sx={{ color: grey[500] }}
            />
          )}

          <Typography variant="body2" pl={1}>
            {option.name}
          </Typography>
        </Stack>
        <Typography
          variant="body2"
        >{`¥${option.amount?.toLocaleString()}`}</Typography>
      </Stack>
    </ListItemButton>
  );
});
