import liff from "@line/liff";
import { Amplify, Auth } from "aws-amplify";
//@ts-ignore
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";

const awsconfig = {
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AUTH_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_REGION,
  aws_user_pools_id: process.env.REACT_APP_AUTH_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AUTH_USER_WEB_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_OAUTH_DOMAIN,
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_SIGN_IN,
    redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_SIGN_OUT,
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["NAME"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_user_files_s3_bucket: process.env.REACT_APP_AWS_S3_BUCKET,
  aws_user_files_s3_bucket_region: process.env.REACT_APP_AWS_REGION,
};

Amplify.configure(awsconfig);

export const AppAuth = {
  initializeLiff: () => {
    return new Promise<void>((res, rej) => {
      liff
        .init({
          liffId: process.env.REACT_APP_LIFF_ID!,
          withLoginOnExternalBrowser: false,
        })
        .then((result) => {
          if (liff.isLoggedIn() && liff.isInClient()) {
            //liff.login();
            res();
          } else {
            res();
          }
        })
        .catch((err) => {
          rej(err);
        });
    });
  },

  signIn: () => {
    return new Promise<void>(async (res, rej) => {
      await Auth.federatedSignIn({ customProvider: "LINE" })
        .then((result) => {
          Amplify.configure({
            aws_appsync_authenticationType: "OPENID_CONNECT",
          });
          res();
        })
        .catch((err) => rej(err));
    });
  },

  federatedSignIn: (provider: string) => {
    return new Promise<void>(async (res, rej) => {
      switch (provider) {
        case "LINE":
          await Auth.federatedSignIn({ customProvider: "LINE" });
          res();
          /*Amplify.configure({
            aws_appsync_authenticationType: "OPENID_CONNECT",
          });*/
          break;
        case "Google":
          await Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Google,
          });
          res();
          break;
        case "Facebook":
          await Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Facebook,
          });
          res();
          break;
        case "Amazon":
          await Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Amazon,
          });
          res();
          break;
        case "Apple":
          await Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Apple,
          });
          res();
          break;
        default:
          rej();
          break;
      }
    });
  },

  signOut: () => {
    return new Promise<void>(async (res, rej) => {
      Auth.signOut()
        .then(() => res())
        .catch((err) => rej(err));
    });
  },
  getToken: () => {
    return Auth.currentSession()
      .then((session) => session)
      .catch((err) => console.log(err));
  },
};
