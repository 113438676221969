import "./App.css";
import liff from "@line/liff";
import { ApplicationStoreProvider } from "./provider/ApplicationStoreProvider";
import { ApplicationPreferenceProvider } from "./provider/ApplicationPreferenceProvider";
import { AppRouter } from "./route";
import { Authenticator } from "@aws-amplify/ui-react";

function App() {
  return (
    <Authenticator.Provider>
      <ApplicationStoreProvider>
        <ApplicationPreferenceProvider>
          <AppRouter />
        </ApplicationPreferenceProvider>
      </ApplicationStoreProvider>
    </Authenticator.Provider>
  );
}

export default App;
