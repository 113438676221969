import { Box, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

type Props = {
  orderUnit: number
  addUnitHandler:(e:React.MouseEvent<HTMLButtonElement, MouseEvent>) =>void
  removeUnitHandler:(e:React.MouseEvent<HTMLButtonElement, MouseEvent>) =>void
};
export const OrderUnitInput = React.memo<Props>(({orderUnit, addUnitHandler, removeUnitHandler}) => {
  return (
    <Stack
      direction="row"
      p={1}
      spacing={2.5}
      alignItems="center"
      justifyContent="center"
    >
      <Box>
        <IconButton
          onClick={removeUnitHandler}
        >
          <RemoveCircleOutlineIcon />
        </IconButton>
      </Box>

      <Typography variant="h2" px={2} pt={0.3} borderBottom="1px solid">
        {orderUnit}個
      </Typography>

      <Box>
        <IconButton
          onClick={addUnitHandler}
        >
          <AddCircleOutlineIcon />
        </IconButton>
      </Box>
    </Stack>
  );
});
