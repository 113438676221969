import { Shop } from "../../LocalModel";
import liff from "@line/liff";

export const ShopRepository = () => {
  const getShop = () => {
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/restaurants/${process.env.REACT_APP_RESTAURANT_ID}`;
    
    return new Promise<Shop>(async (res, rej) => {
      await fetch(baseUrl, {
        method: "GET",
        mode:'cors',
        headers: {
          Authorization: liff.getAccessToken() ?? "gl072145451919",
          "Channel-Id": process.env.REACT_APP_LINE_CHANNEL_ID!,
        },
      })
        .then((result) => {
          res(result.json() as unknown as Shop);
        })
        .catch((err) => rej(err));
    });
  };
  return { getShop };
};
