import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
type Props = DialogProps & {
  onClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};
const Image = styled("img")({
  display: "block",
  width: "100%",
  height: "auto",
});
export const IllustPlateDescriptionModal = React.memo<Props>(
  ({ onClose, ...props }) => {
    return (
      <Dialog fullScreen {...props}>
        <DialogTitle>イラストプレートについて</DialogTitle>
        <DialogContent>
          <Stack spacing={1.5}>
            <Box>
              <Image src="/images/les-cinq_illustplate.jpg" />
            </Box>
            <Box>
              <Typography variant="body2" lineHeight={1.75}>
                レサンクエピスではイラストプレートをお付けできるサービスを行なっています。
                お子様のお誕生日や結婚記念日、イベント、パーティー様々な用途でお客様のお気に入りなイラストや写真、
                企業のロゴ等をチョコレートのプレートにいたします。大切な記念日をより良い思い出にしませんか？
              </Typography>
            </Box>
            <Box>
              <Divider>
                <Chip label="代金表" />
              </Divider>
              <Box my={2}>
                <TableContainer
                  component={Paper}
                  variant="outlined"
                  elevation={0}
                >
                  <Table size="medium">
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "#efefef" }}>
                        <TableCell
                          sx={{ borderRight: "1px solid #d4d4d4" }}
                        ></TableCell>
                        <TableCell
                          sx={{ borderRight: "1px solid #d4d4d4" }}
                          align="center"
                        >
                          イラスト
                        </TableCell>
                        <TableCell align="center">写真</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          component="th"
                          sx={{
                            backgroundColor: "#efefef",
                            borderRight: "1px solid #d4d4d4",
                          }}
                        >
                          4,5号ケーキ
                        </TableCell>
                        <TableCell align="center" sx={{ borderRight: "1px solid #d4d4d4" }}>¥990</TableCell>
                        <TableCell align="center">¥1,210</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          component="th"
                          sx={{
                            backgroundColor: "#efefef",
                            borderRight: "1px solid #d4d4d4",
                          }}
                        >
                          6号ケーキ
                        </TableCell>
                        <TableCell
                          sx={{ borderRight: "1px solid #d4d4d4" }}
                          align="center"
                        >
                          ¥1,100
                        </TableCell>
                        <TableCell align="center">¥1,320</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography variant="caption">
                  ※表示金額は税込み価格です。
                </Typography>
              </Box>
            </Box>
            <Box>
              <Divider>
                <Chip label="ご注文手順" />
              </Divider>
              <Stack
                my={2}
                spacing={2}
                sx={{
                  "& img": { width: "90%", m: "1em auto 1em" },
                  "& h3": { mb: "1em" },
                }}
              >
                <Box>
                  <Typography variant="h3" fontWeight={600}>
                    1.イラストプレート対応商品の選択
                  </Typography>
                  <Typography variant="body2" lineHeight={1.75}>
                    購入商品の選択欄にあるイラストプレート付属商品を選択してください。
                  </Typography>
                  <Image src="/images/illust_desc_1.jpg" />
                  <Typography variant="body2" lineHeight={1.75}>
                    選択後、写真・イラストの選択に進んでください。
                  </Typography>
                  <Image src="/images/illust_desc_2.jpg" />
                </Box>
                <Box>
                  <Typography variant="h3" fontWeight={600}>
                    2.画像を選択する
                  </Typography>
                  <Image src="/images/illust_desc_3.jpg" />
                  <Typography variant="body2" lineHeight={1.75}>
                    印刷したい写真・イラストを選択してください。なお、印刷可能な画像はJPEG(.jpg/.JPEG)のみです。
                    また縦945px横945pxより大きい画像が印刷可能となります。
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h3" fontWeight={600}>
                    3.画像の編集
                  </Typography>
                  <Image src="/images/illust_desc_4.jpg" />
                  <Typography variant="body2" lineHeight={1.75}>選択した画像・イラストの拡大・縮小、回転など画像の編集が可能です。</Typography>
                </Box>
                <Box>
                  <Typography variant="h3" fontWeight={600}>
                    4.画像の決定
                  </Typography>
                  <Image src="/images/illust_desc_5.jpg" />
                  <Typography variant="body2" lineHeight={1.75}>画像の選択・編集の完了後、印刷する画像の決定を押して完了となります。決定後はお買い物を続けるか、注文の確定に進むことができます。</Typography>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>閉じる</Button>
        </DialogActions>
      </Dialog>
    );
  }
);
