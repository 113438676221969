import { ExternalOrderInput } from "../../Model";

export const OrderRepository = (accessToken: string | null) => {
  const endpoint = `${process.env.REACT_APP_API_SERVER_URL}/restaurants/${process.env.REACT_APP_RESTAURANT_ID}/orders`;
  const createOrder = (data: ExternalOrderInput) => {
    return new Promise<void>((res, rej) => {
      fetch(endpoint, {
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken !== null ? accessToken : "gl072145451919",
          "Channel-Id": process.env.REACT_APP_LINE_CHANNEL_ID!,
        },
        method: "POST",
        mode: "cors",
        body: JSON.stringify(data),
      }).then((_)=>res()).catch((err)=>rej(err));
    });
  };

  return { createOrder };
};
