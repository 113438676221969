import { Box, Button, Typography } from "@mui/material";
import React from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";
export const PreviousPageHeader = React.memo(() => {
  const navigate = useNavigate();
  return (
    <Box px={1}>
      <Button
        variant="text"
        startIcon={<ArrowBackIosIcon />}
        onClick={(e) => {
          navigate(-1);
        }}
      >
        <Typography pt={0.5} variant="subtitle1">
          戻る
        </Typography>
      </Button>
    </Box>
  );
});
