import { Box, Divider, ListItemButton, Stack, Typography } from "@mui/material";
import React from "react";
import { grey } from "@mui/material/colors";
import { Price } from "../../DAL/Model";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

type Props = {
  price: Price;
  selectedPriceId?: string | null;
  clickHandler: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    price: Price
  ) => void;
};

export const ProductRadioInput = React.memo<Props>(
  ({ price, selectedPriceId, clickHandler }) => {
    return (
      <ListItemButton sx={{ p: 0 }} onClick={(e) => clickHandler(e, price)}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          p={1.5}
          m={0}
        >
          <Stack direction="row">
            {price.id === selectedPriceId ? (
              <RadioButtonCheckedIcon fontSize="small" color="primary" />
            ) : (
              <RadioButtonUncheckedIcon
                fontSize="small"
                sx={{ color: grey[500] }}
              />
            )}
            <Typography variant="body2" pl={1}>
              {price.name}
            </Typography>
          </Stack>
          <Typography variant="body2">{`¥${price.amount?.toLocaleString()}`}</Typography>
        </Stack>
      </ListItemButton>
    );
  }
);
