import { Box, Typography, Stack, Divider } from "@mui/material";
import React from "react";
import { PreviousPageHeader } from "../components/Header/PreviousPageHeader";

export const TermPage = React.memo(() => {

  return (
    <Box>
      <PreviousPageHeader />
      <Box py={4} px={2}>
        <Typography variant="h3" textAlign="center" fontWeight={600}>
          特定商取引に関する法律に基づく表記
        </Typography>
        <Stack spacing={4} mt={4}>
          <Box>
            <Typography variant="h4" fontWeight={600} mb={0.5}>
              事業者名
            </Typography>
            <Divider />
            <Typography variant="body1" mt={1}>
              レサンクエピス
            </Typography>
          </Box>
          <Box>
            <Typography variant="h4" fontWeight={600} mb={0.5}>
              所在地
            </Typography>
            <Divider />
            <Typography variant="body1" mt={1}>
              東京都品川区南品川2-17-26 アスキー南品川III 1F
            </Typography>
          </Box>
          <Box>
            <Typography variant="h4" fontWeight={600} mb={0.5}>
              電話番号
            </Typography>
            <Divider />
            <Typography variant="body1" mt={1}>
              03-5460-3382
            </Typography>
          </Box>
          <Box>
            <Typography variant="h4" fontWeight={600} mb={0.5}>
              販売価格
            </Typography>
            <Divider />
            <Typography variant="body1" mt={1}>
              販売価格は表示された金額(消費税込み)としたします。
            </Typography>
          </Box>
          <Box>
            <Typography variant="h4" fontWeight={600} mb={0.5}>
              代金の支払い時期、支払い方法
            </Typography>
            <Divider />
            <Typography variant="body1" mt={1}>
              支払いは本システムで注文完了後、商品の受取り時に店舗にてお支払いになります。
            </Typography>
          </Box>
          <Box>
            <Typography variant="h4" fontWeight={600} mb={0.5}>
              返品について
            </Typography>
            <Divider />
            <Typography variant="body1" mt={1}>
              商品に欠陥がある場合を除き、基本的には返品には応じません。
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
});
