import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CartPage } from "../pages/CartPage";
import { IllustSelectPage } from "../pages/IllustSelectPage";
import { MenuPage } from "../pages/MenuPage";
import { OAuthCallbackPage } from "../pages/OAuthCallbackPage";
import { PaymentConfirmPage } from "../pages/PaymentConfirmPage";
import { OrderProcessingPage} from "../pages/OrderProcessingPage";
import { TopPage } from "../pages/TopPage";
import { LiffReservationClient } from "../theme/LiffReservationClient";
import { ProtectedRoute } from "./ProtectedRoute";
import { TermPage } from "../pages/TermPage";
import ModalProvider from "mui-modal-provider";


export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LiffReservationClient />}>
          <Route element={<ProtectedRoute />}>
            <Route path={"/"} element={<TopPage />} />
            <Route path={"/menu/:productId"} element={<MenuPage />} />
            <Route path={"/illust/:orderId"} element={<IllustSelectPage />} />
            <Route path={"/cart"} element={ <ModalProvider><CartPage /></ModalProvider>} />
            <Route path={"/payment/:paymentId/comfirm"} element={<PaymentConfirmPage />} />
            <Route path="/order/process" element={<OrderProcessingPage/>} />
            <Route element={<OAuthCallbackPage />} path="/oauth/callback" />
          </Route>
          <Route path="/term" element={<TermPage/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
