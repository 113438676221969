import Grid from "@mui/material/Grid";
import { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Box, colors, Stack, Typography } from "@mui/material";
import { useModal } from "mui-modal-provider";

import { useApplicationStore } from "../../provider/ApplicationStoreProvider";
import React from "react";
import { ResourceOperationEvent } from "../../DAL/ResourceEvents";
import { useImageSelecter } from "../../provider/ImageSelectProvider";
import { Order } from "../../DAL/Model";
import { TrimmingModal } from "../Modal/TrimmingModal";
import { UploadImage } from "../../utils/storage";

export type ImageObject = {
  path: string;
  naturalWidth: number;
  naturalHeight: number;
};
type Props = {
  children?: React.ReactNode;
  order: Order;
  onComfirmHandler: (uploadImage: UploadImage)=>void
};
export const ImageSelecter = React.memo<Props>(({ children, order, onComfirmHandler}) => {
  const [uploadImage, setUploadImage] = useState<File>();
  const [uploadImageSrc, setUploadImageSrc] = useState<string>();
  //const [canShowDialog, setCanShowDialog] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errMessage, setErrorMessage] = useState<string>();
  const { showModal } = useModal();
  const [imageObj, setImageObj] = useState<ImageObject>();
  const {selecterStore} = useImageSelecter();

  const registCroppedImageHandler = useCallback((blob: Blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      setUploadImageSrc(reader.result as string);
    };
  }, []);

  const TrimmingModalHandler = useCallback((imgObj: ImageObject) => {
    const modal = showModal(TrimmingModal, {
      title: "アップロード画像の編集",
      uploadImage: imgObj,
      orderId: order.id,
      productName: `${order.productName}_${order.price!.name}`,
      onConfirm: (blob: Blob) => {
        const image = new Image();
        image.onload = () => {
          //console.log(`croped:${image.naturalWidth}`);
          //console.log(`croped:${image.naturalHeight}`);
          //console.log(`croped:${blob.size}`);
        };
        image.src = URL.createObjectURL(blob);
        registCroppedImageHandler(blob);
        modal.hide();
      },
      onCancel: () => {
        modal.hide();
      },
    });
  },[registCroppedImageHandler, showModal]);

  const imageSelectedHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files![0]) return;
      const image = new Image();

      image.addEventListener("load", () => {
        const obj = {
          path: image.src,
          naturalWidth: image.naturalWidth,
          naturalHeight: image.naturalHeight,
        };
        //console.log(
        //  `originW:${obj.naturalWidth}, originH:${obj.naturalHeight}, path: ${obj.path}`
        //);
        //console.log(event.target.files![0].size);
        if (obj.naturalWidth > 945 && obj.naturalHeight > 945) {
          setImageObj(obj);
          setUploadImage(event.target.files![0]);
          setUploadImageSrc(obj.path);
          TrimmingModalHandler(obj);
          setHasError(false);
        } else {
          setErrorMessage(
            `画像が小さすぎます。選択した画像は「縦:${obj.naturalHeight}px,横:${obj.naturalWidth}」です。`
          );
          setHasError(true);
        }
        //URL.revokeObjectURL(image.src)   // (寸法だけを知りたいので)破棄する
      });
      image.src = URL.createObjectURL(event.target.files![0]);
      //console.log(image.src);
    },
    [TrimmingModalHandler]
  );

  const nextHandler = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      if (selecterStore.uploadImage === null) {
        setErrorMessage("画像の選択は必須です。");
        setHasError(true);
      } else {
        onComfirmHandler(selecterStore.uploadImage);
      }
    },
    [onComfirmHandler, selecterStore.uploadImage]
  );


  useEffect(() => {
    //console.log(uploadImage);
    //console.log(uploadImageSrc);
    //if (state.uploadImage !== null)
    //  registCroppedImageHandler(state.uploadImage.blob!);
  }, []);

  return (
    <Stack spacing={1.5}>
      <Typography variant="caption">
        印刷可能な画像はJPEG(.jpg/.JPEG)のみです。
        <br />
        また縦945px横945pxより大きい画像が印刷可能となります。
      </Typography>
      <Box>
        {!uploadImageSrc ? null : (
          <img
            src={uploadImageSrc}
            alt="selected_image"
            width="100%"
            style={{ display: "block", borderRadius: "50%" }}
          />
        )}
      </Box>
      <Box>
        <input
          type="file"
          accept="image/jpeg"
          onChange={imageSelectedHandler}
          id="raised-button-file"
          style={{ display: "none" }}
        />
        <label htmlFor="raised-button-file">
          <Button variant="outlined" component="span" color="primary">
            画像を選択する。
          </Button>
        </label>
      </Box>
      {hasError ? (
        <Typography variant="caption" display="block" color={colors.red[400]}>
          {errMessage}
        </Typography>
      ) : null}
      {children}

      <Button
        variant="contained"
        color="primary"
        disableElevation
        fullWidth
        onClick={nextHandler}
        sx={{ mt: 1, mr: 1 }}
      >
        この画像に決定する
      </Button>
    </Stack>
  );
});
