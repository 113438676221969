import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import CropRotateIcon from "@mui/icons-material/CropRotate";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
  Slider,
} from "@mui/material";
import { useCallback, useState } from "react";
import Cropper, { Area } from "react-easy-crop";
import "./style.css";
import { getCroppedImg } from "../../utils/cropImage";
import { UploadImage } from "../../utils/storage";
import { ImageObject } from "../Input/ImageSelecter";
import {
  ImageSelecterEvent,
  useImageSelecter,
} from "../../provider/ImageSelectProvider";
import React from "react";
type Props ={
  image: ImageObject;
  orderId: string;
  productName: string;
  onCancel: Function;
  onConfirm: Function;
}

export const ImageCropper = React.memo<Props>(({ image, onConfirm, orderId, productName, onCancel }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState<number>(0);
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [bottomNaviActiveIndex, setBottomIndex] = useState(2);
  const [cropedFile, setCropedFile] = useState<File>();
  const { imageSelectDispatch } = useImageSelecter();
  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  return (
    <Box>
      <Cropper
        image={image.path}
        crop={crop}
        rotation={rotation}
        zoom={zoom}
        cropShape="round"
        aspect={1}
        onCropChange={setCrop}
        onRotationChange={setRotation}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
      />
      {bottomNaviActiveIndex === 0 ? (
        <Paper
          sx={{
            position: "absolute",
            bottom: 56,
            left: 0,
            width: "100%",
            height: 64,
            padding: "0 16px",
            borderRadius: 0,
          }}
        >
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => setZoom(zoom as number)}
          />
        </Paper>
      ) : bottomNaviActiveIndex === 1 ? (
        <Paper
          sx={{
            position: "absolute",
            bottom: 56,
            left: 0,
            width: "100%",
            height: 64,
            padding: "0 16px",
            borderRadius: 0,
          }}
        >
          <Slider
            value={rotation}
            min={0}
            max={360}
            step={1}
            aria-labelledby="Rotation"
            onChange={(e, rotation) => setRotation(rotation as number)}
          />
        </Paper>
      ) : null}
      <Paper
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          height: 56,
        }}
      >
        <BottomNavigation
          showLabels
          //value={value}
          onChange={(event, newValue) => {
            setBottomIndex(newValue);
            if (newValue === 3) {
              getCroppedImg(image.path, croppedAreaPixels!, rotation).then(
                async (result) => {
                  const imageName =  `${orderId}_${productName}.jpg`;
                  const blob = await fetch(result as string).then((r) =>
                    r.blob()
                  );
                  const file = await fetch(result as string).then(
                    async (r) => new File([await r.blob()], imageName)
                  );

                  imageSelectDispatch({
                    type: ImageSelecterEvent.SET_UPLOAD_IMAGE,
                    payload: {
                      name: imageName,
                      data: file,
                      blob: blob,
                    } as UploadImage,
                  });
                  
                  onConfirm(blob);
                }
              );
            }
          }}
        >
          <BottomNavigationAction
            label="拡大/縮小"
            icon={<CenterFocusStrongIcon />}
          />
          <BottomNavigationAction label="回転" icon={<CropRotateIcon />} />
          <BottomNavigationAction
            label="確認"
            icon={<CheckCircleOutlineIcon />}
          />
          <BottomNavigationAction label="完了" icon={<FileUploadIcon />} />
        </BottomNavigation>
      </Paper>
    </Box>
  );
});
