type BusinessHours = {
  isAvailable: boolean;
  open: {
    hour: number;
    minutes: number;
  };
  close: {
    hour: number;
    minutes: number;
  };
};

type WeekSchedule = {
  lunch?: BusinessHours;
  dinner?: BusinessHours;
};

type DateGeneraterProps = {
  needTodaysOrder?: boolean | null;
  dayOff?: number[] | null; //Day number
  notDayOff?: Date[] | null;
  holidays?: Date[] | null;
  weekSchedule?: WeekSchedule[] | null;
  ignoreDiffMinutes?: number;
};
export const OrderAcceptableDateGenerater = ({
  needTodaysOrder,
  dayOff,
  notDayOff,
  holidays,
  weekSchedule,
  ignoreDiffMinutes,
}: DateGeneraterProps) => {
  const setupDate = () => {
    //@ts-ignore
    //基準を今日に設定
    let baseDate = new Date(Date().toLocaleString({ timeZone: "Asia/Tokyo" }));
    // 受け取り日の設定
    // 日曜日の16時以降は4日後、月曜日の場合は3日後、火曜日の場合は2日後、それ以外は1日後
    switch (new Date().getDay()) {
      case 0:
        if (baseDate.getHours() >= 16){
          baseDate.setDate(baseDate.getDate() + 4);
        }
        break;
      case 1:
        baseDate.setDate(baseDate.getDate() + 3);
        holidays?.forEach((hol) => {
          if (isHoliday(hol, baseDate)) {
            baseDate.setDate(baseDate.getDate() + 2);
          }
        });
        break;
      case 2:
        baseDate.setDate(baseDate.getDate() + 2);
        // 起点となる日付が休日の場合は2日後
        holidays?.forEach((hol) => {
          if (isHoliday(hol, baseDate)) {
            baseDate.setDate(baseDate.getDate() + 2);
          }
        });
        break;
      default:
        // 今日が不定休日かどうか
        const isTodayHoliday = holidays?.map((hol) => isHoliday(hol, baseDate)).reduce((acc, cur) => acc || cur, false);
        if (isTodayHoliday) {
          //今日が不定休日だった場合は2日後
          baseDate.setDate(baseDate.getDate() + 2);
          // 2日後が休日の場合はさらに2日後
          holidays?.forEach((hol) => {
            if (isHoliday(hol, baseDate)) {
              baseDate.setDate(baseDate.getDate() + 2);
            }
          });
        } else {
          // 今日の注文が不可能な場合は1日後
          if (!needTodaysOrder) baseDate.setDate(baseDate.getDate() + 1);
          // 注文時の時間が16時以降は1日後
          if (baseDate.getHours() >= 16)
            baseDate.setDate(baseDate.getDate() + 1);
        }
        break;
    }
    //

    return (
      [...Array(7)]
        // 7日分の日付を作成
        .map(
          (_, idx) =>
            new Date(
              baseDate.setDate(
                baseDate.getDate() + ((idx) => (idx > 0 ? 1 : 0))(idx)
              )
            )
        )
        //定休日を除外
        .filter((day) => isDayOff(day))
        //不定休日を除外
        .filter(
          (day) =>
            !holidays
              ?.map((hol) => isHoliday(hol, day))
              .reduce((acc, cur) => acc || cur, false)
        )
    );
  };
  const setupTime = (orderDate: Date, startAfternoon?: boolean) => {
    const schedule = weekSchedule?.at(orderDate.getDay());
    const orderTimes = [
      ...createOrderTimes(
        orderDate,
        startAfternoon
          ? {
              ...schedule?.lunch!,
              open: {
                hour: 13,
                minutes: 0,
              },
            }
          : schedule?.lunch!
      ),
      ...createOrderTimes(orderDate, schedule?.dinner!),
    ];
    //@ts-ignore
    const now = new Date(Date().toLocaleString({ timeZone: "Asia/Tokyo" }));
    if (now.getDate() === orderDate.getDate()) {
      return orderTimes.filter((t) => isAvailable(now, t));
    } else {
      return orderTimes;
    }
  };

  const isAvailable = (now: Date, target: Date) => {
    const diffMinutes = (target.getTime() - now.getTime()) / (1000 * 60);
    return diffMinutes >= (ignoreDiffMinutes ?? 720);
  };

  const isHoliday = (holiday: Date, day: Date) => {
    return (
      holiday.getMonth() === day.getMonth() &&
      holiday.getDate() === day.getDate()
    );
  };
  const isDayOff = (day: Date) => {
    return !(dayOff?.includes(day.getDay()) && !isNotDayOff(day));
  };
  const isNotDayOff = (day: Date) => {
    return notDayOff
      ?.map(
        (nd) =>
          nd.getFullYear() === day.getFullYear() &&
          nd.getMonth() === day.getMonth() &&
          nd.getDate() === day.getDate()
      )
      .reduce((acc, cur) => acc || cur, false);
  };

  const createOrderTimes = (orderDate: Date, businessHours: BusinessHours) => {
    if (!businessHours.isAvailable) return [];
    const timeSpan = 15;
    const firstStartTime = new Date(
      orderDate.getFullYear(),
      orderDate.getMonth(),
      orderDate.getDate(),
      businessHours.open.hour,
      businessHours.open.minutes,
      0,
      0
    );
    const firstEndTime = new Date(
      orderDate.getFullYear(),
      orderDate.getMonth(),
      orderDate.getDate(),
      businessHours.close.hour,
      businessHours.close.minutes,
      0,
      0
    );

    const loopNum =
      1 +
      (firstEndTime.getTime() - firstStartTime.getTime()) / (1000 * 60) / 15;

    return [...Array(Math.trunc(loopNum))].map(
      (_, idx) => new Date(firstStartTime.getTime() + timeSpan * idx * 60000)
    );
  };

  return { setupDate, setupTime };
};
