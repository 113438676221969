import { Box, Stack, Typography } from "@mui/material";
import ModalProvider from "mui-modal-provider";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OrderCard } from "../components/Card/OrderCard";
import { PreviousPageHeader } from "../components/Header/PreviousPageHeader";
import { ImageSelecter } from "../components/Input/ImageSelecter";
import { Order } from "../DAL/Model";
import { ResourceOperationEvent } from "../DAL/ResourceEvents";
import { useApplicationStore } from "../provider/ApplicationStoreProvider";
import { ImageSelecterProvider } from "../provider/ImageSelectProvider";
import { UploadImage } from "../utils/storage";

export const IllustSelectPage = () => {
  const { store, dispatch } = useApplicationStore();
  const [selectedOrder, setOrder] = useState<Order>();
  const navigate = useNavigate();
  useEffect(() => {
    if (store.illustPlatePreOrder !== null) {
      setOrder(store.illustPlatePreOrder);
    } else {
      navigate("/");
    }
  }, [store.illustPlatePreOrder]);

  const attacheImageHandler = useCallback(
    (uploadImage: UploadImage) => {
      dispatch({
        type: ResourceOperationEvent.ADD_ILLUST_ORDER,
        payload: uploadImage,
      });
      navigate("/cart");
    },
    [dispatch, navigate]
  );

  return selectedOrder ? (
    <Box>
      <PreviousPageHeader />
      <Box p={2}>
        <Stack spacing={1}>
          <Typography variant="h3" fontWeight={600}>
            注文中の商品
          </Typography>
          <OrderCard order={selectedOrder} disableDelete/>
        </Stack>

        <Typography variant="h3" fontWeight={600} mt={3} mb={1}>
          イラストプレート画像を選択してください。
        </Typography>
        <ImageSelecterProvider>
          <ModalProvider>
            <ImageSelecter
              onComfirmHandler={attacheImageHandler}
              order={selectedOrder}
            />
          </ModalProvider>
        </ImageSelecterProvider>
      </Box>
    </Box>
  ) : null;
};
