import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { Link } from "react-router-dom";

export const Footer = React.memo(() => {
  return (
    <Box component="footer" textAlign="center" p={1} bgcolor={grey[300]} sx={{"& a":{
      textDecoration: "none"
    }}}>
      <Typography variant="caption">
        <Link to="/term">特定商取引に関する法律に基づく表記</Link>
      </Typography>
    </Box>
  );
});
