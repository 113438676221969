import { useAuthenticator } from "@aws-amplify/ui-react";
import { Backdrop, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";

export const SignInProgress: React.FC = () => {
  const { route, authStatus} = useAuthenticator((context) => [context.route, context.authStatus]);
  const [message, setMessage] = useState<string>("");
  useEffect(() => {
    if (route === "idle") {
      setMessage("初期化中");
    } else if (route === "setup") {
      setMessage("認証中");
    } else {
      setMessage("通信中");
    }
  }, [route]);
  return (
    <Backdrop open={true}>
      <Card elevation={0}>
        <Stack
          spacing={2}
          pt={2}
          px={2}
          pb={1}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
          <Typography variant="body2">{message}</Typography>
        </Stack>
      </Card>
      <Typography></Typography>
    </Backdrop>
  );
};
