import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { SignInProgress } from "../components/Progress";

//define interface Props then set arguments ({test}:Props)

//https://localhost:3000/oauth/callback/?redirect1=cookie&redirect2=plate
//https://liff.line.me/1657081960-VDpmoWzy/?redirect1=cookie
export const OAuthCallbackPage = () => {
  const { route, authStatus } = useAuthenticator((context) => [
    context.route,
    context.authStatus,
  ]);
  const navigate = useNavigate();

  useEffect(() => {
    if (route === "authenticated" && authStatus === "authenticated") {
      navigate("/")
    }
  }, [route, navigate, authStatus]);

  return <SignInProgress />;
};
