import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "./../../utils/yupExtended";
import { OrderAcceptableDateGenerater } from "../../utils/OrderAcceptableDateGenerater";
import { InternalOrderInput, Payment } from "../../DAL/Model";

const phoneRegExp = /^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/;

// todays order flag
const need_todays_order = false;
// set day off => sun:0, mon:1, tue:2, wed:3, thu:4, fri:5, sat:6
const day_off = [1];
// set holidays, but substract Month Number by 1
const holidays = [
  new Date(2024, 2 - 1, 27),
  new Date(2024, 2 - 1, 13),
  new Date(2024, 7 - 1, 16),  
  new Date(2024, 8 - 1, 13),
  new Date(2024, 9 - 1, 17),
  new Date(2024, 9 - 1, 24),
  new Date(2024, 10 - 1, 15),
  new Date(2024, 11 - 1, 5),  
];
const not_day_off = [
  new Date(2024, 2 - 1, 12),
  new Date(2024, 7 - 1, 15),  
  new Date(2024, 8 - 1, 12),
  new Date(2024, 9 - 1, 16),
  new Date(2024, 9 - 1, 23),
  new Date(2024, 10 - 1, 14),
  new Date(2024, 11 - 1, 4),
];

const schedules = [
  //sun
  {
    lunch: {
      isAvailable: true,
      open: {
        hour: 11,
        minutes: 0,
      },
      close: {
        hour: 18,
        minutes: 0,
      },
    },
    dinner: {
      isAvailable: false,
      open: {
        hour: 17,
        minutes: 30,
      },
      close: {
        hour: 22,
        minutes: 0,
      },
    },
  },
  //mon
  {
    lunch: {
      isAvailable: true,
      open: {
        hour: 11,
        minutes: 0,
      },
      close: {
        hour: 18,
        minutes: 0,
      },
    },
    dinner: {
      isAvailable: false,
      open: {
        hour: 17,
        minutes: 30,
      },
      close: {
        hour: 22,
        minutes: 0,
      },
    },
  },
  //tue
  {
    lunch: {
      isAvailable: true,
      open: {
        hour: 11,
        minutes: 0,
      },
      close: {
        hour: 18,
        minutes: 0,
      },
    },
    dinner: {
      isAvailable: false,
      open: {
        hour: 17,
        minutes: 30,
      },
      close: {
        hour: 22,
        minutes: 0,
      },
    },
  },
  //wed
  {
    lunch: {
      isAvailable: true,
      open: {
        hour: 11,
        minutes: 0,
      },
      close: {
        hour: 18,
        minutes: 0,
      },
    },
    dinner: {
      isAvailable: false,
      open: {
        hour: 17,
        minutes: 30,
      },
      close: {
        hour: 22,
        minutes: 0,
      },
    },
  },
  //thu
  {
    lunch: {
      isAvailable: true,
      open: {
        hour: 11,
        minutes: 0,
      },
      close: {
        hour: 18,
        minutes: 0,
      },
    },
    dinner: {
      isAvailable: false,
      open: {
        hour: 17,
        minutes: 30,
      },
      close: {
        hour: 22,
        minutes: 0,
      },
    },
  },
  //fri
  {
    lunch: {
      isAvailable: true,
      open: {
        hour: 11,
        minutes: 0,
      },
      close: {
        hour: 18,
        minutes: 0,
      },
    },
    dinner: {
      isAvailable: false,
      open: {
        hour: 17,
        minutes: 30,
      },
      close: {
        hour: 22,
        minutes: 0,
      },
    },
  },
  //sat
  {
    lunch: {
      isAvailable: true,
      open: {
        hour: 11,
        minutes: 0,
      },
      close: {
        hour: 18,
        minutes: 0,
      },
    },
    dinner: {
      isAvailable: false,
      open: {
        hour: 17,
        minutes: 30,
      },
      close: {
        hour: 22,
        minutes: 0,
      },
    },
  },
];
const ignore_diff_minutes = 720;
const afternoonList = [
  "prod_R1VZ3BikcaP7GVoS", //モンブラン
  "prod_8vKbmXYG51ozzdqh", //シャンティショコラ
  "prod_fdd1UnLfmYuNDWz6", //シャンティショコラいちごもり
  "prod_pVuDqUAxfHE0djl0", //シャンティフリュイ
  "prod_eZwuPoJVGQ8XuBJI", //タルトフリュイ
];

const formSchema = yup.object({
  name: yup.string().required("氏名の入力は必須です。"),
  tel: yup
    .string()
    .trimPhoneNumber()
    .matches(phoneRegExp, { message: "電話番号の形式に誤りがあります。" })
    .required("電話番号の入力は必須です。"),
  date: yup.string().required("受取日時の選択は必須です。"),
  paymentWay: yup.string().required("支払方法の選択は必須です。"),
  memo: yup.string().nullable().notRequired(),
});

type Props = {
  payment: Payment;
  submitHandler: (internalInput: InternalOrderInput) => void;
};

export const OrderForm = React.memo<Props>(({ payment, submitHandler }) => {
  const dateGenerater = OrderAcceptableDateGenerater({
    needTodaysOrder: need_todays_order,
    dayOff: day_off,
    notDayOff: not_day_off,
    holidays: holidays,
    weekSchedule: schedules,
    ignoreDiffMinutes: ignore_diff_minutes,
  });
  const [orderDate, setOrderDate] = useState<Date[]>();
  const [orderTime, setOrderTime] = useState<Date[]>();
  const [selectedDateString, setSelectedDateString] = useState<string>("");
  const [selectedTimeString, setSelectedTimeString] = useState<string>("");
  const [isSelectedPaymentWay, selectPaymentWay] = useState<boolean>(false);
  const [startAfternoon, setStartAfternoon] = useState<boolean>(false);
  const [allowSave, setAllowSave] = useState<boolean>(false);
  
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<InternalOrderInput>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      name: "",
      tel: "",
      date: "",
      paymentWay: "",
      memo: "",
    },
  });

  const dateSelectHandler = useCallback(
    (e: SelectChangeEvent) => {
      setSelectedDateString(e.target.value);
      setOrderTime(
        dateGenerater.setupTime(new Date(e.target.value), startAfternoon)
      );
      if (selectedTimeString) {
        setValue("date", `${e.target.value}T${selectedTimeString}`);
        trigger("date");
      }
    },
    [dateGenerater, selectedTimeString, setValue, startAfternoon, trigger]
  );

  const timeSelectHandler = useCallback(
    (e: SelectChangeEvent) => {
      setSelectedTimeString(e.target.value);
      setValue("date", `${selectedDateString}T${e.target.value}`);
      trigger("date");
    },
    [selectedDateString, setValue, trigger]
  );

  const paymentWayBtnHandler = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!isSelectedPaymentWay) {
        selectPaymentWay(true);
        setValue("paymentWay", "cash");
      } else {
        selectPaymentWay(false);
        setValue("paymentWay", "");
      }
      if (errors.paymentWay) trigger("paymentWay");
    },
    [errors.paymentWay, isSelectedPaymentWay, setValue, trigger]
  );

  const saveInfoHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setAllowSave(e.target.checked);
    },
    []
  );

  const innerSubmitHandler = useCallback((data:InternalOrderInput) => {
    submitHandler(data);
    if (allowSave) {
      localStorage.setItem("name", getValues("name"));
      localStorage.setItem("tel", getValues("tel"));
    } else {
      localStorage.removeItem("name");
      localStorage.removeItem("tel");
    }
  }, [allowSave, getValues, submitHandler]);

  useEffect(() => {
    setOrderDate(dateGenerater.setupDate());
    setStartAfternoon(
      payment.orders
        ?.map((order) => order.productId)
        .map((id) => afternoonList.includes(id!))
        .reduce((acc, cur) => cur || acc, false) ?? false
    );
    if(localStorage.getItem("name")){
      setValue("name",localStorage.getItem("name")!)
      trigger("name")
      setAllowSave(true)
    }
    if(localStorage.getItem("tel")){
      setValue("tel",localStorage.getItem("tel")!)
      trigger("tel")
      setAllowSave(true)
    }
  }, []);

  return (
    <Stack spacing={2}>
      <Box>
        <Typography variant="subtitle1" fontWeight={600} mb={1}>
          受取日時
        </Typography>
        <Stack direction="row" spacing={2}>
          <FormControl fullWidth size="small">
            <InputLabel id="order-date">
              <Typography variant="caption">受取日</Typography>
            </InputLabel>
            <Select
              margin="none"
              labelId="order-date"
              size="small"
              fullWidth
              label="受取日"
              placeholder="受取日をしてしてください。"
              onChange={dateSelectHandler}
              value={selectedDateString}
              error={errors.date ? true : false}
            >
              {orderDate
                ? orderDate.map((date) => (
                    <MenuItem
                      key={date.getTime()}
                      value={date.toLocaleDateString()}
                    >
                      {date.toLocaleDateString()}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
          <FormControl fullWidth size="small">
            <InputLabel id="order-time">
              <Typography variant="caption">受取時間</Typography>
            </InputLabel>
            <Select
              margin="none"
              labelId="order-time"
              size="small"
              label="受取時間"
              fullWidth
              onChange={timeSelectHandler}
              disabled={selectedDateString === ""}
              value={selectedTimeString}
              error={errors.date ? true : false}
            >
              {orderTime
                ? orderTime.map((date) => (
                    <MenuItem
                      key={date.getTime()}
                      value={date.toLocaleTimeString()}
                    >
                      {date.toLocaleTimeString().replace(/:00$/, "")}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Stack>
        {errors.date ? (
          <Typography variant="caption" color="error" pl={2}>
            {errors.date.message}
          </Typography>
        ) : null}
      </Box>
      <Box>
        <Typography variant="subtitle1" fontWeight={600} mb={1}>
          お客様情報
        </Typography>
        <Stack spacing={2}>
          <TextField
            margin="none"
            variant="outlined"
            label="お名前"
            size="small"
            fullWidth
            {...register("name")}
            error={"name" in errors}
            helperText={errors.name?.message}
          />
          <TextField
            margin="none"
            variant="outlined"
            label="電話番号"
            size="small"
            fullWidth
            {...register("tel")}
            error={"tel" in errors}
            helperText={errors.tel?.message}
          />
        </Stack>
        <Stack direction="row" alignItems="center">
          <Checkbox size="small" onChange={saveInfoHandler} checked={allowSave} />
          <Typography variant="caption">
            この情報を次回以降に使用できるようにする。
          </Typography>
        </Stack>
      </Box>
      <Box>
        <Typography variant="subtitle1" fontWeight={600}>
          支払方法
          <Typography variant="caption"> ※店頭払いのみとなります</Typography>
        </Typography>
        <Grid container mt={1} mb={0.5}>
          <Grid item xs={6}>
            <Button
              variant={isSelectedPaymentWay ? "contained" : "outlined"}
              disableElevation
              fullWidth
              onClick={paymentWayBtnHandler}
            >
              店頭払い
            </Button>
          </Grid>
        </Grid>
        {errors.paymentWay ? (
          <Typography variant="caption" color="error" pl={2}>
            {errors.paymentWay.message}
          </Typography>
        ) : null}
      </Box>
      <Box>
        <Typography variant="subtitle1" fontWeight={600}>
          その他
        </Typography>
        <Typography variant="caption" mb={1} display="block">
          メッセージプレートは、1枚まで無料でお付けすることが可能です。ご希望の場合は、その旨とメッセージの内容をご記入ください。2枚以上のプレートをご希望の場合、2枚目以降は1枚ごとに50円の追加料金が発生します。その際の料金は店頭でお支払いいただきます。
        </Typography>
        <TextField
          margin="none"
          variant="outlined"
          multiline
          rows={3}
          label="備考欄"
          placeholder="例) メッセージプレート希望。「〇〇ちゃん、お誕生日おめでとう」"
          fullWidth
          size="small"
          {...register("memo")}
        />
      </Box>
      <Button
        variant="contained"
        disableElevation
        fullWidth
        onClick={handleSubmit(innerSubmitHandler)}
      >
        購入する
      </Button>
    </Stack>
  );

});
