import { Product } from "../../Model";

export const ProductRepository = () => {
  const getList = () => {
    return new Promise<Product[]>(async (res, rej) => {
      await fetch(`${process.env.REACT_APP_APP_ROOT}/data/products.json`, {
        method: "GET",
      })
        .then(async (result) => res(result.json() as unknown as Product[]))
        .catch((err) => rej(err));
    });
  };
  return { getList };
};
