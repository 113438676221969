import { createContext, useContext, useMemo, useReducer } from "react";
import { UploadImage } from "../utils/storage";

export type ImageSelecterStore = {
  uploadImage: UploadImage | null;
};

export enum ImageSelecterEvent {
  SET_UPLOAD_IMAGE,
}

export type ImageSelecterAction = {
  type: ImageSelecterEvent.SET_UPLOAD_IMAGE;
  payload: UploadImage;
};

type ImageSelecterState = {
  selecterStore: ImageSelecterStore;
  imageSelectDispatch: React.Dispatch<ImageSelecterAction>;
};
const initialData: ImageSelecterStore = {
  uploadImage: null,
};

const ImageSelecterContext = createContext(
  {} as ImageSelecterState 
);

const imageSelecterReducer = (
  selecterStore: ImageSelecterStore,
  action: ImageSelecterAction
) => {
  switch (action.type) {
    case ImageSelecterEvent.SET_UPLOAD_IMAGE:
      return {
        ...selecterStore,
        uploadImage: action.payload,
      };
    default:
      return selecterStore
  }
};
type Props = {
  children?: React.ReactNode;
};
export const ImageSelecterProvider: React.FC<Props> = (props) => {
  const [selecterStore, imageSelectDispatch] = useReducer(
    imageSelecterReducer,
    initialData
  );
  const value = useMemo(
    () => ({ selecterStore, imageSelectDispatch }),
    [selecterStore, imageSelectDispatch]
  );
  return <ImageSelecterContext.Provider value={value} {...props} />
};


export const useImageSelecter = () =>{
  const context = useContext(ImageSelecterContext);
  if(typeof context === "undefined"){
    throw new Error("useImageSelecter must be within a ImageSelecterProvider.");
  }
  return context;
}