import { ThemeProvider } from "@emotion/react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ModalProvider from "mui-modal-provider";
import PersonIcon from "@mui/icons-material/Person";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import LiffReservationDrawer from "./LiffReservationDrawer";
import { LiffReservationTheme } from "./LiffReservationTheme";
import jaLocale from "date-fns/locale/ja";
import {
  Container,
  CssBaseline,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useApplicationStore } from "../provider/ApplicationStoreProvider";
import ThemeModeSwitch from "../components/Switch/ThemeModeSwitch";
import {
  PreferenceEvent,
  useApplicationPreference,
} from "../provider/ApplicationPreferenceProvider";
import { ScrollToTop } from "../utils/ScrollToTop";

export const LiffReservationClient = () => {
  const navigate = useNavigate();
  const { shopId, customerId } = useParams();
  const { store } = useApplicationStore();
  const { preference } = useApplicationPreference();

  return (
    <ThemeProvider theme={LiffReservationTheme(preference.mode)}>
      <CssBaseline />
      <ScrollToTop />
      <Paper sx={{ borderRadius: 0 }}>
        <Container
          sx={{ display: "block", minHeight: "100vh", padding: 0 }}
          maxWidth="md"
        >
          <Outlet />
        </Container>
      </Paper>
    </ThemeProvider>
  );
};
