export enum ResourceOperationEvent {
  FETCH_MENU,
  FETCH_CATEGORY,
  GET_SHOP,
  GET_CUSTOMER,
  CREATE_ORDER,
  FETCH_LIFF,
  UPLOAD_IMAGE,
  ADD_PAYMENT,
  ADD_ILLUST_PRE_ORDER,
  ADD_ILLUST_ORDER,
  DELETE_ORDER,
  RESET_ALL,
  SET_ORDER_TRANSACTION
}