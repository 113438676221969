import { PaletteMode } from "@mui/material";
import { createContext, useReducer, useMemo, useContext } from "react";

type PREFERENCE = {
  mode: PaletteMode;
};

const initialPreference: PREFERENCE = {
  mode: (localStorage.getItem("themeMode") ?? "light") as PaletteMode,
};

export enum PreferenceEvent {
  SET_MODE,
}

type PreferenceActionType = {
  type: PreferenceEvent.SET_MODE;
  payload: PaletteMode;
};

const ApplicationPreferenceContext = createContext(
  {} as {
    preference: PREFERENCE;
    preferenceDispatcher: React.Dispatch<PreferenceActionType>;
  }
);

const reducer = (preference: PREFERENCE, action: PreferenceActionType) => {
  switch (action.type) {
    case PreferenceEvent.SET_MODE:
      localStorage.setItem("themeMode", action.payload);
      return { ...preference, mode: action.payload };
    default:
      return preference;
  }
};

type Props = {
  children?: React.ReactNode;
};

export const ApplicationPreferenceProvider: React.FC<Props> = (props) => {
  const [preference, preferenceDispatcher] = useReducer(
    reducer,
    initialPreference
  );
  const value = useMemo(
    () => ({ preference, preferenceDispatcher }),
    [preference]
  );
  return <ApplicationPreferenceContext.Provider value={value} {...props} />;
};

export const useApplicationPreference = () => {
  const context = useContext(ApplicationPreferenceContext);
  if(typeof context === "undefined"){
    throw new Error("useApplicaitonPreference must be within a ApplicationPreferenceProvider.")
  }
  return context;
};
