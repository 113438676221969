import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Stack,
} from "@mui/material";

type DeleteDialogProps = DialogProps & {
  title: string;
  description: string;
  onCancel: (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onConfirm: (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const DeleteItemDialog: React.FC<DeleteDialogProps> = ({
  title,
  description,
  onCancel,
  onConfirm,
  ...props
}) => {
  return (
    <Dialog {...props}>
      <Box p={1}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText>{description}</DialogContentText>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary" variant="outlined">
            キャンセル
          </Button>
          <Button
            onClick={(e) => {
              onConfirm(e);
            }}
            color="error"
            variant="contained"
            disableElevation
          >
            削除する
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};