import { useEffect } from "react";
import { Outlet, RouteProps } from "react-router-dom";
import { useApplicationStore } from "../provider/ApplicationStoreProvider";
import liff from "@line/liff";
import { ResourceOperationEvent } from "../DAL/ResourceEvents";
import { ProgressMessageCard } from "../components/Card/ProgressMessageCard";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { AppAuth } from "../utils/AppAuth";

export const ProtectedRoute: React.FC<RouteProps> = () => {
  const { store, dispatch } = useApplicationStore();
  const { route, authStatus, user } = useAuthenticator((context) => [
    context.route,
    context.authStatus,
    context.user,
  ]);

  useEffect(() => {

    if (route === "setup" && authStatus === "configuring") {
      AppAuth.initializeLiff().then(() => AppAuth.federatedSignIn("LINE"));
    }

    if (
      authStatus === "authenticated" &&
      route === "authenticated" &&
      store.lineId === null
    ) {
      dispatch({
        type: ResourceOperationEvent.FETCH_LIFF,
        payload: user.attributes?.name!,
      });
    }
  }, [authStatus, dispatch, route]);

  return authStatus === "authenticated" ? (
    <Outlet />
  ) : authStatus === "configuring" ? (
    <ProgressMessageCard />
  ) : null;
};
