import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback} from "react";
import { OrderCard } from "../components/Card/OrderCard";
import { useApplicationStore } from "../provider/ApplicationStoreProvider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { OrderForm } from "../components/Form/OrderForm";
import { PreviousPageHeader } from "../components/Header/PreviousPageHeader";
import { useNavigate } from "react-router-dom";
import { InternalOrderInput} from "../DAL/Model";

export const PaymentConfirmPage: React.FC = () => {
  const { store, dispatch } = useApplicationStore();
  const navigate = useNavigate();

  const submitHandler = useCallback((internalInput: InternalOrderInput)=>{
    dispatch({
      type: "START_ORDER",
      payload: {
        internalInput: internalInput,
        payment: store.payment
      }
    })
    navigate('/order/process')
  },[dispatch, store.payment])

  return (
    <Box>
      <PreviousPageHeader />
      <Box p={2}>
        <Typography variant="subtitle1" fontWeight={600}>
          注文を確認して、購入情報を入力してください。
        </Typography>
        <Paper variant="outlined" sx={{ margin: "12px 0"}}>
          <Stack px={1} py={2} spacing={1} bgcolor="#efefef">
            <Stack direction="row" justifyContent="space-between" px={1}>
              <Typography>合計金額</Typography>
              <Typography>¥{store.payment.total.toLocaleString()}</Typography>
            </Stack>
            <Accordion variant="outlined" sx={{"& div":{bgcolor:"#efefef"}}}>
              <AccordionSummary
                sx={{
                  fontSize: 14,
                  m: 0,
                  ">.MuiAccordionSummary-content": {
                    m: 0
                  },
                }}
                expandIcon={<ExpandMoreIcon />}
              >
                詳細を見る
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={1}>
                  {store.payment.orders?.map((order) => (
                    <OrderCard key={order.id} order={order} disableDelete/>
                  ))}
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Stack>
        </Paper>
        <OrderForm payment={store.payment} submitHandler={submitHandler}/>
      </Box>
    </Box>
  );
};
