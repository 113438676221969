import {
  Box,
  Button,
  Chip,
  Divider,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { OrderCard } from "../components/Card/OrderCard";
import { TransactionMessagePanel } from "../components/Panel/TransactionMessagePanel";
import {
  OrderTransactionStatus,
  useApplicationStore,
} from "../provider/ApplicationStoreProvider";
import liff from "@line/liff";
import { ResourceOperationEvent } from "../DAL/ResourceEvents";

export const OrderProcessingPage: React.FC = () => {
  const { store, dispatch } = useApplicationStore();
  const navigate = useNavigate();
  const closeHandler = useCallback((e:React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
    e.preventDefault();
    if(liff.isInClient()){
      liff.closeWindow()
    }else{
      dispatch({
        type: ResourceOperationEvent.RESET_ALL,
        payload: null
      })
      navigate("/")
    }
  },[dispatch, navigate])
  useEffect(() => {
    if (store.payment === null) navigate("/");
  }, [navigate, store.payment]);
  return (
    <Box pt={1} px={1}>
      <Stack>
        {store.orderTransaction !== null ? (
          <TransactionMessagePanel transaction={store.orderTransaction} />
        ) : (
          <Skeleton variant="rounded" />
        )}
      </Stack>
      {store.orderTransaction?.status === OrderTransactionStatus.succeed ? (
        <Stack mt={2.5} spacing={1.5}>
          <Box>
            <Typography variant="h3" fontWeight={600} mb={1}>
              お買い上げありがとうございます。
            </Typography>
            <Typography variant="body2">
              注文履歴はトークルームに送信された注文カードをもってかえさせていただきます。
            </Typography>
          </Box>
          <Divider>
            <Chip label="ご注文情報" />
          </Divider>
          <Paper variant="outlined">
            <Stack px={1} pt={2} pb={1} spacing={1}>
              <Stack spacing={1}>
                {store.payment.orders?.map((order) => (
                  <OrderCard key={order.id} order={order} disableDelete />
                ))}
              </Stack>
              <Stack direction="row" justifyContent="space-between" px={1}>
                <Typography>合計金額</Typography>
                <Typography>¥{store.payment.total.toLocaleString()}</Typography>
              </Stack>
            </Stack>
          </Paper>
          <Button variant="contained" fullWidth disableElevation onClick={closeHandler}>
            閉じる
          </Button>
        </Stack>
      ) : null}
    </Box>
  );
};
