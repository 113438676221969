import {
  Box,
  Grid,
  ListItem,
  ListItemButton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "../../../DAL/LocalModel";
import { Product } from "../../../DAL/Model";

type MenuListItemProps = {
  menu: Product;
};
const Image = styled("img")({
  display: "block",
  width: "100%",
  height: "auto",
  maxWidth: "100px",
});
const StyledListItem = styled(ListItem)({
  padding: 0,
});
export const MenuListItem = React.memo<MenuListItemProps>(({ menu }) => {
  return (
    <StyledListItem
      sx={{
        borderBottomWidth: 1,
        borderBottomColor: "border.main",
        borderBottomStyle: "solid",
      }}
    >
      <ListItemButton component={Link} to={`/menu/${menu.id.split("_").at(1)}`}>
        <Box>
          <Grid container alignItems="center">
            <Grid item xs maxWidth={100}>
              <Image src={`${menu.image}.jpg`} />
            </Grid>
            <Grid item xs>
              <Stack p={1} spacing={0.5}>
                <Typography variant="h3">{menu.name}</Typography>
                <Typography>{`¥${menu.price?.at(0)?.amount.toLocaleString()}`}</Typography>
                <Typography variant="caption">
                  {menu.description?.length! < 37
                    ? menu.description
                    : menu.description?.substring(0, 35) + "…"}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </ListItemButton>
    </StyledListItem>
  );
});
