import { useEffect, useState } from "react";
import { useApplicationStore } from "../provider/ApplicationStoreProvider";
import DocumentMeta from "react-document-meta";
import {
  Box,
  Chip,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate,useSearchParams } from "react-router-dom";
import { MenuList } from "../components/List/MenuList/MenuList";
import { ProgressMessageCard } from "../components/Card/ProgressMessageCard";
import { RightFloatingFab } from "../components/Button/FloatingButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Footer } from "../components/Footer/Footer";

export const TopPage: React.FC = () => {
  const { store, dispatch } = useApplicationStore();
  const [selectedCategoryId, setCategoryId] = useState<string>();
  const [search, setSearch] = useSearchParams();
  const navigate = useNavigate();
  const meta = {
    title: "メニュー一覧",
  };

  useEffect(() => {
    if (store.menu === null) {
      dispatch({
        type: "APP_DATA_FETCHING",
        payload: null,
      });
    }
    if (store.category !== null) {
      if (search.get("cat") !== null) {
        setCategoryId(
          store.category.find((cat) => cat.id === search.get("cat"))?.id
        );
      } else {
        setCategoryId(store.category?.at(0)?.id!);
      }
    }
  }, [store.menu, store.category]);

  return (
    <DocumentMeta {...meta}>
      {store.menu !== null ? (
        <Stack>
          <Box pt={1} px={1}>
            {store.category !== null ? (
              <Stack direction="row" spacing={1} justifyContent="center">
                {store.category.map((c) => (
                  <Chip
                    key={c.id}
                    label={c.name}
                    color="primary"
                    variant={
                      c.id === selectedCategoryId ? "filled" : "outlined"
                    }
                    clickable={c.id !== selectedCategoryId}
                    onClick={(e) => {
                      e.preventDefault();
                      setSearch({ cat: c.id });
                      setCategoryId(c.id);
                    }}
                  />
                ))}
              </Stack>
            ) : (
              <Skeleton />
            )}
          </Box>
          <Box minHeight="calc(100vh - 80.5px)">
            <Stack spacing={2} py={0}>
              <MenuList
                menu={store.menu.filter(
                  (m) => m.categoryId === selectedCategoryId
                )}
              />
            </Stack>
          </Box>
          <Footer/>
        </Stack>
      ) : (
        <Stack alignItems="center">
          <ProgressMessageCard
            message="ロード中"
          />
        </Stack>
      )}
      {store.payment.orders?.length! > 0 ? (
        <RightFloatingFab
          color="primary"
          variant="circular"
          onClick={(e) => {
            e.preventDefault();
            navigate("/cart");
          }}
        >
          <Stack alignItems="center">
            <ShoppingCartIcon fontSize="small" />
            <Typography variant="caption">カート</Typography>
          </Stack>
        </RightFloatingFab>
      ) : null}
    </DocumentMeta>
  );
};
