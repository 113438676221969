import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogProps,
} from "@mui/material";
import React from "react";
import { ImageCropper } from "../ImageCropper/ImageCropper";

import { ImageObject } from "../Input/ImageSelecter";

type TrimmingModalProps = DialogProps & {
  title: string;
  uploadImage: ImageObject;
  orderId: string;
  productName: string;
  onCancel: Function;
  onConfirm: Function;
};

export const TrimmingModal = React.memo<TrimmingModalProps>(
  ({
    title,
    uploadImage,
    orderId,
    productName,
    onCancel,
    onConfirm,
    ...props
  }) => {
    return (
      <Dialog fullScreen {...props}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <ImageCropper
            image={uploadImage}
            onConfirm={onConfirm}
            onCancel={onCancel}
            orderId={orderId}
            productName={productName}
          />
        </DialogContent>
      </Dialog>
    );
  }
);
