import { Category } from "../../Model"

export const CategoryRepository = () =>{
  const getList = () =>{
    return new Promise<Category[]>(async (res, rej)=>{
      await fetch(`${process.env.REACT_APP_APP_ROOT}/data/category.json`,{
        method: "GET"
      }).then((result)=>
        res(result.json() as unknown as Category[])
      ).catch((err)=>rej(err))
    })
  }
  return {getList}
}