import { Storage } from "aws-amplify";
export type UploadImage = {
  name: string | undefined | null;
  data: File | undefined | null;
  blob: Blob | undefined | null;
};
export const getImageFromS3 = (filename: string) => {
  return new Promise<string>(async (res, rej) => {
    if (filename === null) rej();
    await Storage.get(filename)
      .then((result) => res(result))
      .catch((err) => rej(err));
  });
};
export const removeImageFromS3 = (filename: string) => {
  return new Promise<void>(async (res, rej) => {
    await Storage.remove(filename)
      .then((result) => res())
      .catch((err) => rej());
  });
};
export const uploadImage2S3 = (image: UploadImage) => {
  return new Promise<void>(async (res, rej) => {
    if (image.name !== null) {
      await Storage.put(image.name!, image.data, {
        contentType: image.data?.type,
      })
        .then((result) => res())
        .catch((err) => rej(err));
    } else {
      rej(new Error("image file has no name..."));
    }
  });
};

export const batchUploadImage2S3 = (images: UploadImage[]) => {
  return new Promise<void>(async (res, rej) => {
    Promise.all(
      images.map((image) =>
        Storage.put(image.name!, image.data, {
          contentType: image.data?.type,
        })
      )
    )
      .then((_) => res())
      .catch((err) => rej(err));
  });
};
