import styled from "@emotion/styled";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { Order } from "../../DAL/Model";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type Props = {
  order: Order;
  disableDelete?: boolean;
  deleteHandler?:(e:React.MouseEvent<HTMLButtonElement, MouseEvent>)=>void;
};

const Image = styled("img")({
  display: "block",
  width: "100%",
  height: "auto",
});

export const OrderCard = React.memo<Props>(({ order, disableDelete, deleteHandler }) => {
  return (
    <Card variant="outlined">
      <Grid container p={1} columnSpacing={2}>
        <Grid item xs={2.5}>
          <Image
            srcSet={`${order.thumbnail}.webp`}
            src={`${order.thumbnail}.jpg`}
          />
        </Grid>
        <Grid item xs={9.5}>
          <Typography variant="body2">
            {order.productName}{" "}
            <Typography variant="caption">({order.category})</Typography>
          </Typography>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2">{`${order.price?.name} ${order.unit}個`}</Typography>
            <Typography variant="body2">
              ¥{(order.price?.amount! * order.unit).toLocaleString()}
            </Typography>
          </Stack>
          {order.options ? (
            <Stack>
              {order.options.map((opt) => (
                <Stack
                  key={opt.price.id}
                  direction="row"
                  justifyContent="space-between"
                >
                  <Typography variant="body2">{opt.price.name}</Typography>
                  <Typography variant="body2">
                    ¥{opt.price?.amount.toLocaleString()}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          ) : null}
        </Grid>
      </Grid>
      {order.image ? (
        <Accordion elevation={0}>
          <AccordionSummary
            sx={{
              fontSize: 14,
              m: 0,
              ">.MuiAccordionSummary-content": {
                m: 0,
              },
            }}
            expandIcon={<ExpandMoreIcon />}
          >
            イラストプレート画像を見る
          </AccordionSummary>
          <AccordionDetails>
            <Image
              src={window.URL.createObjectURL(order.image.blob!)}
              style={{ borderRadius: "50%" }}
            />
          </AccordionDetails>
        </Accordion>
      ) : null}
      {disableDelete ? null : (
        <Box>
          <Divider />
          <Stack>
            <Button size="small" color="error" onClick={deleteHandler}>削除</Button>
          </Stack>
        </Box>
      )}
    </Card>
  );
});
