import { Divider, List, ListProps } from "@mui/material";
import React from "react";
import { Product } from "../../../DAL/Model";
import { MenuListItem } from "./MenuListItem";

type MenuListProps = ListProps & {
  menu: Product[];
};
export const MenuList = React.memo<MenuListProps>(({ menu, ...props }) => {
  return (
    <List {...props} sx={{pb:0}}>
      <Divider />
      {menu?.map((item) => (
        <MenuListItem key={item.id} menu={item} />
      ))}
    </List>
  );
});
