import { CircularProgress, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { OrderTransaction, OrderTransactionStatus } from "../../provider/ApplicationStoreProvider";

type PaymentTransactionProps = {
  transaction: OrderTransaction;
};
export const TransactionMessagePanel: React.FC<PaymentTransactionProps> =
  React.memo(({ transaction }) => {
    return (
      <Paper variant="outlined">
        <Stack
          direction="row"
          spacing={2}
          px={2}
          py={1}
          justifyContent="flex-start"
          alignItems="center"
        >
          {transaction.status === OrderTransactionStatus.entry ||
          transaction.status === OrderTransactionStatus.processing ? (
            <CircularProgress size="1.3em" />
          ) : transaction.status === OrderTransactionStatus.succeed ? (
            <CheckCircleIcon color="primary" />
          ) : (
            <ErrorIcon color="error" />
          )}
          <Typography variant="body1" pt=".35em">
            {transaction.message}
          </Typography>
        </Stack>
      </Paper>
    );
  });
