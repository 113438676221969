import styled from "@emotion/styled";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useModal } from "mui-modal-provider";
import React, { useCallback } from "react";
import { Product } from "../../DAL/Model";
import { IllustPlateDescriptionModal } from "../Modal/IllustPlateDescriptionModal";

type Props = {
  product: Product;
  hasIllustPlate: boolean;
};
const Image = styled("img")({
  display: "block",
  width: "100%",
  height: "auto",
});

export const ProductInfoPanel = React.memo<Props>(
  ({ product, hasIllustPlate }) => {
    const { showModal } = useModal();
    const showModalHandler = useCallback((e:React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
      e.preventDefault();
      const modal = showModal(IllustPlateDescriptionModal,{
        onClose:(e:React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{modal.hide()}
      })
    },[])
    return (
      <Box>
        <Box>
          <Image
            srcSet={`${product.image}.webp`}
            src={`${product.image}.jpg`}
          />
        </Box>
        <Box m={2}>
          <Typography variant="h3" fontWeight={600}>
            {product.name}
          </Typography>
        </Box>
        <Stack px={2} spacing={1}>
          <Typography variant="body2">{product.description}</Typography>
          {hasIllustPlate ? (
            <Typography variant="caption">
              ※この商品はイラストプレートの注文が可能です。イラストプレートの詳細は<Button variant="text" color="info" sx={{m:0, p:0, textDecoration:"underline"}} onClick={showModalHandler}>コチラ</Button>からご確認ください。
            </Typography>
          ) : null}
        </Stack>
      </Box>
    );
  }
);
