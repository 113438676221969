import {
  Backdrop,
  Card,
  CardProps,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

type ProgressMessageCardProps = CardProps & {
  message?: string;
};
export const ProgressMessageCard = React.memo<ProgressMessageCardProps>(
  ({ message, ...props }) => {
    return (
      <Backdrop open={true}>
        <Card {...props}>
          <Stack
            spacing={2}
            pt={2}
            px={2}
            pb={1}
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
            <Typography variant="body2">
              {message ? message : "初期化中"}
            </Typography>
          </Stack>
        </Card>
      </Backdrop>
    );
  }
);
