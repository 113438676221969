import { Box, Button, Card, Grid, Stack, Typography } from "@mui/material";
import { useModal } from "mui-modal-provider";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import { OrderCard } from "../components/Card/OrderCard";
import { DeleteItemDialog } from "../components/Dialog/DeleteItemDialog";
import { Footer } from "../components/Footer/Footer";
import { Back2TopHeader } from "../components/Header/Back2TopHeader";
import { ResourceOperationEvent } from "../DAL/ResourceEvents";
import { useApplicationStore } from "../provider/ApplicationStoreProvider";

export const CartPage: React.FC = () => {
  const { store, dispatch } = useApplicationStore();
  const { showModal } = useModal();
  const deleteDialogHandler = useCallback(
    (orderId: string) => {
      const modal = showModal(DeleteItemDialog, {
        title: "削除の確認",
        description: "この商品の注文を削除してもよろしいですか。",
        onCancel: () => {
          modal.hide();
        },
        onConfirm: () => {
          dispatch({
            type: ResourceOperationEvent.DELETE_ORDER,
            payload: orderId,
          });
          modal.hide();
        },
      });
    },
    [dispatch, showModal]
  );

  return (
    <Box>
      <Back2TopHeader />
      <Box p={2} minHeight="calc(100vh - 80.5px)">
        <Typography variant="h3" fontWeight={600} mb={1}>
          注文商品一覧
        </Typography>
        <Box mb={2}>
          {store.payment.orders !== null && store.payment.orders.length > 0 ? (
            <Stack spacing={1.5}>
              <Typography variant="body2">
                カートに追加した商品の編集はできません。個数や大きさの変更をする場合は、お手数をおかけしますが一度注文を削除し、再度ご注文ください。　
              </Typography>
              {store.payment.orders?.map((order) => (
                <OrderCard
                  key={order.id}
                  order={order}
                  deleteHandler={(_) => deleteDialogHandler(order.id)}
                />
              ))}
            </Stack>
          ) : (
            <Card variant="outlined" elevation={0}>
              <Box p={1}>
                <Typography variant="body2">
                  カートに入っている商品はありません。
                </Typography>
              </Box>
            </Card>
          )}
        </Box>
        {store.payment.orders !== null && store.payment.orders.length > 0 ? (
          <Grid container spacing={1}>
            <Grid item>
              <Button
                variant="outlined"
                disableElevation
                fullWidth
                component={Link}
                to={`/`}
              >
                トップへ戻る
              </Button>
            </Grid>
            <Grid item flexGrow={2}>
              <Button
                variant="contained"
                disableElevation
                fullWidth
                component={Link}
                to={`/payment/${store.payment.id}/comfirm`}
              >
                購入へ進む
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Button
            variant="contained"
            disableElevation
            fullWidth
            component={Link}
            to={`/`}
          >
            トップへ戻る
          </Button>
        )}
      </Box>
      <Footer />
    </Box>
  );
};
