import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  List,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApplicationStore } from "../provider/ApplicationStoreProvider";
import { teal } from "@mui/material/colors";
import { Order, Price, Product } from "../DAL/Model";
import { PreviousPageHeader } from "../components/Header/PreviousPageHeader";
import { ProductInfoPanel } from "../components/Panel/ProductInfoPanel";
import { ProductRadioInput } from "../components/Input/ProductRadioInput";
import { OrderUnitInput } from "../components/Input/OrderUnitInput";
import { OptionRadioInput } from "../components/Input/OptionRadioInput";
import { ResourceOperationEvent } from "../DAL/ResourceEvents";
import { Footer } from "../components/Footer/Footer";
import ModalProvider from "mui-modal-provider";

const { customAlphabet } = require("nanoid");
const alphabet =
  "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
const nanoid = customAlphabet(alphabet, 16);

export const MenuPage: React.FC = () => {
  const { productId } = useParams();
  const { store, dispatch } = useApplicationStore();
  const navigate = useNavigate();
  const [product, setProduct] = useState<Product>();
  const [isSetPrice, setIsSetPrice] = useState<boolean>(false);
  const [isLimitOver, setLimitOver] = useState<boolean>(false);
  const [hasIllustPlate, setIllustPlate] = useState<boolean>(false);
  const [didSelectIllustPlate, setSelectIllustPlate] = useState<boolean>(false);
  const [order, setOrder] = useState<Order>({
    id: `oi_${nanoid()}`,
    paymentId: store.payment.id,
    productId: null,
    price: null,
    unit: 1,
  });

  const productRadioInputHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, price: Price) => {
      if (price.id !== order.price?.id) {
        setSelectIllustPlate(price.name.includes("イラストプレート"));
        setOrder({ ...order, price: price });
        setIsSetPrice(true);
      } else {
        setSelectIllustPlate(false);
        setOrder({ ...order, price: null });
        setIsSetPrice(false);
      }
    },
    [order]
  );
  const optionRadioInputHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, option: Price) => {
      if (order.options?.find((item) => item.price.id === option.id)) {
        setOrder({
          ...order,
          options: order.options.filter((opt) => opt.price.id !== option.id),
        });
      } else {
        setOrder({
          ...order,
          options: [
            ...(order.options?.filter(
              (opt) => opt.price.optionId !== option.optionId
            ) ?? []),
            {
              orderId: order.id,
              price: option,
              unit: 1,
            },
          ],
        });
      }
    },
    [order]
  );

  const canAddCart = useCallback(
    (selectedProduct: Product, nextOrderUnitNumber: number) => {
      const sameProductOrders = store.payment.orders?.filter(
        (od) => od.productId === `prod_${productId}`
      );

      if (sameProductOrders) {
        //console.log(sameProductOrders);
        if (sameProductOrders?.length! === 0) return true;
        if (
          selectedProduct?.limit! >=
          sameProductOrders.reduce((acc, cur) => acc + cur.unit, 0) +
            nextOrderUnitNumber
        ) {
          setLimitOver(false);
          return true;
        } else {
          //console.log(`この商品の注文上限は${selectedProduct?.limit}個です。`);
          setLimitOver(true);
          return false;
        }
      } else {
        return true;
      }
    },
    [store.payment.orders, productId, order.unit]
  );

  const addUnitHandler = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      canAddCart(product!, order.unit + 1);
      setOrder({
        ...order,
        unit: order.unit < product?.limit! ? order.unit + 1 : product?.limit!,
      });
    },
    [canAddCart, product, order]
  );
  const removeUnitHandler = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setOrder({
        ...order,
        unit: order.unit > 1 ? order.unit - 1 : 1,
      });
      canAddCart(product!, order.unit - 1);
    },
    [canAddCart, order, product]
  );

  const handleAddPayment = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      if (didSelectIllustPlate) {
        dispatch({
          type: ResourceOperationEvent.ADD_ILLUST_PRE_ORDER,
          payload: order,
        });
        navigate(`/illust/${order.id}`);
      } else {
        dispatch({
          type: ResourceOperationEvent.ADD_PAYMENT,
          payload: order,
        });
        navigate("/cart");
      }
    },
    [dispatch, didSelectIllustPlate, navigate, order]
  );

  const calcOrderTotal = useCallback((order: Order) => {
    if (!order.price) return 0;
    const productAmount = order.price?.amount * order.unit ?? 0;
    const optionAmount =
      order.options?.reduce(
        (acc, cur) => acc + cur.price?.amount * cur.unit,
        0
      ) ?? 0;
    return productAmount + optionAmount;
  }, []);

  useEffect(() => {
    if (store.menu !== null) {
      const selectedProduct = store.menu.find(
        (item) => item.id === `prod_${productId}`
      );
      if (selectedProduct) {
        setProduct(selectedProduct);
        setOrder({
          ...order,
          productId: selectedProduct.id,
          productName: selectedProduct.name,
          category: store.category?.find(
            (cat) => cat.id === selectedProduct.categoryId
          )?.name,
          thumbnail: selectedProduct.image,
        });
        canAddCart(selectedProduct, 1);
        setIllustPlate(
          selectedProduct.price?.reduce(
            (acc, cur) => cur.name.includes("イラストプレート") || acc,
            false
          )!
        );
      }
    } else {
      navigate("/");
    }
  }, [productId, navigate, store.menu]);

  return product ? (
    <Box>
      <PreviousPageHeader />
      <ModalProvider>
        <ProductInfoPanel product={product} hasIllustPlate={hasIllustPlate} />
      </ModalProvider>
      <Stack py={2} spacing={0}>
        <Stack>
          <Box py={1.5} px={1.5} bgcolor={teal[300]}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography>購入商品</Typography>
              <Chip label="必須" size="small" color="error" />
            </Stack>
          </Box>
          <List sx={{ p: 0 }}>
            {product.price?.map((price) => (
              <ProductRadioInput
                key={price.id}
                price={price}
                selectedPriceId={order.price?.id}
                clickHandler={productRadioInputHandler}
              />
            ))}
          </List>
        </Stack>
        <Stack mt={0}>
          <Box py={1.5} px={1.5} bgcolor={teal[300]}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography width={100}>注文数</Typography>
              <Chip label="必須" size="small" color="error" />
              <Typography variant="caption">{`同一商品の注文は${product.limit}個までです。上限以上の注文は${store.shop?.tel}までお電話ください。`}</Typography>
            </Stack>
          </Box>
          {isLimitOver ? (
            <Alert severity="warning">
              <AlertTitle>注文数上限に達しました。</AlertTitle>
              すでに同一の商品が注文されています。{" "}
              <strong>{`この商品の注文上限は${product?.limit}個です。`}</strong>
            </Alert>
          ) : null}
          <OrderUnitInput
            orderUnit={order.unit}
            addUnitHandler={addUnitHandler}
            removeUnitHandler={removeUnitHandler}
          />
        </Stack>
        <Stack>
          {product.options?.map((op) => (
            <Box key={op.id}>
              <Box py={1.5} px={1.5} bgcolor={teal[300]}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography>{op.name}</Typography>
                  <Chip
                    label={op.required ? "必須" : "オプション"}
                    size="small"
                    color={op.required ? "error" : "info"}
                  />
                </Stack>
              </Box>
              <List sx={{ p: 0 }}>
                {op.price?.map((option) => (
                  <OptionRadioInput
                    key={option.id}
                    option={option}
                    selectedOptionId={
                      order.options?.find((item) => item.price.id === option.id)
                        ?.price.id
                    }
                    clickHandler={optionRadioInputHandler}
                  />
                ))}
              </List>
            </Box>
          ))}
        </Stack>
      </Stack>
      <Box width={0.9} margin="8px auto 32px">
        <Paper variant="outlined">
          <Stack p={1} spacing={1} justifyContent="center" alignItems="center">
            <Stack
              direction="row"
              width={0.9}
              margin="0 auto"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>料金</Typography>
              <Typography>¥{calcOrderTotal(order).toLocaleString()}</Typography>
            </Stack>
            <Box width={0.9} margin="0 auto">
              <Button
                variant="contained"
                disableElevation
                fullWidth
                color="primary"
                disabled={!isSetPrice || isLimitOver}
                onClick={handleAddPayment}
              >
                {!isSetPrice
                  ? "商品を選択してください。"
                  : didSelectIllustPlate
                  ? "写真の選択にすすむ"
                  : "カートに入れる"}
              </Button>
            </Box>
          </Stack>
        </Paper>
      </Box>
      <Footer />
    </Box>
  ) : null;
};
