import { Option } from "../../Model";

export const OptionRepository = () => {
  const getList = () => {
    return new Promise<Option[]>(async (res, rej) => {
      await fetch(`${process.env.REACT_APP_APP_ROOT}/data/options.json`, {
        method: "GET",
      })
        .then((result) => res(result.json() as unknown as Option[]))
        .catch((err) => rej(err));
    });
  };
  return {getList};
};
